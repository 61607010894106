import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'ParkingReservation',
    component: resolve => {require(['@/views/ParkingReservation/ParkingReservation'], resolve)},
    meta:{
      isParkingReservationTurn: true
    },
  },
  {
    path: '/PRSuccess',
    name: 'PRSuccess',
    component: resolve => {require(['@/views/ParkingReservation/PRSuccess'], resolve)},
    meta:{
      isParkingReservationTurn: true
    },
  },
  {
    path: '/PRFail',
    name: 'PRFail',
    component: resolve => {require(['@/views/ParkingReservation/PRFail'], resolve)},
    meta:{
      isParkingReservationTurn: true
    },
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
